import React from 'react';
import { Box, Typography, Grid, Divider, useTheme, useMediaQuery, CardMedia } from '@material-ui/core';
import ImageContainer from '../image-container';
import Clamp from 'react-multiline-clamp';
import CarouselVideoContainer from '../video-carousel';
import CustomLink from '../custom-link';
import PropTypes from 'prop-types';

const CardHorizontalImageVideoTitleDescription = ({
  mediaType,
  lines,
  linkText,
  item,
  itemIndex,
  dataLength,
  dynamic,
  callbackFn,
  autoPlay = false,
  apiData = false,
  isNews = false,
  height = '100%',
}) => {
  const handleIndexChange = () => {
    callbackFn(itemIndex);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      direction='row'
      onClick={dynamic ? handleIndexChange : null}
      style={{ cursor: dynamic ? 'pointer' : null }}>
      {mediaType === 'image' ? (
        <Grid item xs={12} md={5}>
          <Box m={isMobile ? 0 : 1}>
            {apiData ? (
              // <img
              //   src={`${process.env.GATSBY_CMS_API_URL}/static/${
              //     item.file.find((_file) => _file.type !== 'application/pdf').code
              //   }`}
              //   alt={item.title}
              //   style={{ width: '100%' }}
              // />
              <CardMedia
                height={height}
                style={{ width: '100%' }}
                component='img'
                image={`${process.env.GATSBY_CMS_API_URL}/static/${
                  item.file.find((_file) => _file.type !== 'application/pdf').code
                }`}
                alt={item.title || '#GMIS2021'}
              />
            ) : (
              <ImageContainer filename={item.image} altText={'#GMIS2021'} />
            )}
          </Box>
        </Grid>
      ) : mediaType === 'video' ? (
        <Grid item xs={12} lg={5}>
          <Box m={isMobile ? 0 : 1}>
            <CarouselVideoContainer videoUrl={item.video} showControls autoPlay={autoPlay} />
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={12} md={7}>
        <Box mx={isMobile ? 0 : 2} my={isMobile ? 2 : 0}>
          <Typography gutterBottom variant='h6'>
            <Box fontWeight='fontWeightBold' className='text-uppercase'>
              <Clamp lines={2}>{item.title}</Clamp>
            </Box>
          </Typography>

          <Typography
            color='textSecondary'
            gutterBottom
            variant='body1'
            dangerouslySetInnerHTML={{
              __html: apiData ? item.description : item.text,
            }}>
            {/* {apiData ? <Clamp lines={2}>{item.description}</Clamp> : <Clamp lines={2}>{item.text}</Clamp>} */}
          </Typography>

          {apiData && isNews ? (
            item.urlSlug ? (
              <Box my={2} onClick={dynamic ? handleIndexChange : null}>
                <CustomLink
                  linkText={linkText}
                  linkUrl={dynamic ? null : `/news/${item.year}/${item.urlSlug}`}
                  type={2}
                />
              </Box>
            ) : null
          ) : item.url ? (
            <Box my={2} onClick={dynamic ? handleIndexChange : null}>
              <CustomLink linkText={linkText} linkUrl={dynamic ? null : item.url} type={2} />
            </Box>
          ) : apiData ? (
            item.urlSlug ? (
              <Box my={2} onClick={dynamic ? handleIndexChange : null}>
                <CustomLink
                  linkText={linkText}
                  linkUrl={dynamic ? null : `/knowledge-hub/${item.year}/${item.urlSlug}`}
                  type={2}
                />
              </Box>
            ) : null
          ) : item.url ? (
            <Box my={2} onClick={dynamic ? handleIndexChange : null}>
              <CustomLink linkText={linkText} linkUrl={dynamic ? null : item.url} type={2} />
            </Box>
          ) : null}
        </Box>
      </Grid>
      {itemIndex + 1 < dataLength ? (
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

CardHorizontalImageVideoTitleDescription.defaultProps = {
  mediaType: '',
  lines: 3,
  linkText: 'Read more',
};

CardHorizontalImageVideoTitleDescription.propTypes = {
  mediaType: PropTypes.string,
  lines: PropTypes.number,
  linkText: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default CardHorizontalImageVideoTitleDescription;
