import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery, Divider } from '@material-ui/core';
import ImageContainer from '../image-container';
import CarouselVideoContainer from '../video-carousel';
import CustomLink from '../custom-link';
import CardHorizontalImageVideoTitleDescription from '../card-horizontal-image-video-title-description/card-horizontal-image-video-title-description';
import Scrollbar from '../scrollbar';
import Fade from 'react-reveal/Fade';

const SectionContentFeaturedList = ({
  data,
  primaryMediaType,
  secondaryMediaType,
  lines,
  linkText,
  dynamic = false,
  primaryAutoplay = false,
  secondaryAutoplay = false,
  apiData = false,
  isNews = false,
  height,
}) => {
  const [mainIndex, setMainIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const _list = [...data];
    _list.shift();
    setListData(_list);
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleMainItemChange = (item) => {
    setMainIndex(item);
    // setItemIndex(item);

    // if item equals itemIndex, place it at the end of the array

    // for (var i = 0; i < data.length; i++) {
    //   if (data[i].id === data[item].id) {
    //     let removedItem = data.splice(i, 1);
    //     data.push(...removedItem);
    //   }
    // }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={6}>
        <Fade bottom>
          <Box py={1}>
            {primaryMediaType === 'image' ? (
              <Box mb={1}>
                {apiData ? (
                  <img
                    src={`${process.env.GATSBY_CMS_API_URL}/static/${
                      data[0].file.find((_file) => _file.type !== 'application/pdf').code
                    }`}
                    alt={data[0].title}
                    style={{ width: '100%' }}
                  />
                ) : (
                  <ImageContainer
                    filename={dynamic ? data[mainIndex].image : data[0].image}
                    altText={(dynamic ? data[mainIndex].title : data[0].title) || '#GMIS2021'}
                  />
                )}
              </Box>
            ) : (
              <Box mb={1}>
                <CarouselVideoContainer
                  videoUrl={dynamic ? data[mainIndex].video : data[0].video}
                  showControls
                  autoPlay={primaryAutoplay}
                />
              </Box>
            )}
            <Typography color='textPrimary' gutterBottom variant='h6'>
              <Box fontWeight='fontWeightBold'>{dynamic ? data[mainIndex].title : data[0].title}</Box>
            </Typography>
            {apiData ? (
              <Typography
                color='textSecondary'
                gutterBottom
                variant='body1'
                dangerouslySetInnerHTML={{ __html: dynamic ? data[mainIndex].description : data[0].description }}>
                {/* {dynamic ? data[mainIndex].description : data[0].description} */}
              </Typography>
            ) : (
              <Typography
                color='textSecondary'
                gutterBottom
                variant='body1'
                dangerouslySetInnerHTML={{ __html: dynamic ? data[mainIndex].text : data[0].text }}>
                {/* {dynamic ? data[mainIndex].text : data[0].text} */}
              </Typography>
            )}
            {apiData && isNews ? (
              <CustomLink
                linkUrl={
                  dynamic
                    ? `/news/${data[mainIndex].year}/${data[mainIndex].urlSlug}`
                    : `/news/${data[0].year}/${data[0].urlSlug}`
                }
                linkText='Read More'
                type={2}
              />
            ) : apiData ? (
              <CustomLink
                linkUrl={
                  dynamic
                    ? `/knowledge-hub/${data[mainIndex].year}/${data[mainIndex].urlSlug}`
                    : `/knowledge-hub/${data[0].year}/${data[0].urlSlug}`
                }
                linkText='Read More'
                type={2}
              />
            ) : (
              <CustomLink linkUrl={`/${dynamic ? data[mainIndex].url : data[0].url}`} linkText='Read More' type={2} />
            )}
          </Box>
        </Fade>
      </Grid>

      {isMobile ? (
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={12} md={6} lg={6}>
        {isMobile ? (
          <Grid container>
            {listData.map((_item, index) => (
              <Grid item xs={12} key={index}>
                {/* <Fade bottom> */}
                <CardHorizontalImageVideoTitleDescription
                  apiData={apiData ? true : false}
                  isNews={isNews ? true : false}
                  mediaType={secondaryMediaType}
                  lines={lines}
                  linkText={linkText}
                  item={_item}
                  itemIndex={index}
                  dataLength={listData.length}
                  dynamic={dynamic}
                  autoPlay={secondaryAutoplay}
                  callbackFn={handleMainItemChange}
                  height={height}
                />
                {/* </Fade> */}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Scrollbar maxHeight='800px' maxWidth='100%'>
            {/* <Fade bottom> */}
            <Grid container>
              {listData.map((_item, index) => (
                <Grid item xs={12} key={index}>
                  <CardHorizontalImageVideoTitleDescription
                    apiData={apiData ? true : false}
                    isNews={isNews ? true : false}
                    mediaType={secondaryMediaType}
                    lines={lines}
                    linkText={linkText}
                    item={_item}
                    itemIndex={index}
                    dataLength={listData.length}
                    dynamic={dynamic}
                    autoPlay={secondaryAutoplay}
                    callbackFn={handleMainItemChange}
                    height={height}
                  />
                </Grid>
              ))}
            </Grid>
            {/* </Fade> */}
          </Scrollbar>
        )}
      </Grid>
    </Grid>
  );
};

export default SectionContentFeaturedList;
